import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";

const Loader = ({ visible = false }) => {
  const { auth, projects, user_management, admin, proposals } = useSelector(
    (state) => state
  );
  const [loading, setLoading] = useState(visible);

  useEffect(() => {
    setLoading(
      auth?.loading ||
        projects?.loading ||
        user_management?.loading ||
        admin?.loading ||
        proposals?.loading ||
        visible
    );
  }, [
    auth?.loading,
    projects?.loading,
    user_management?.loading,
    admin?.loading,
    proposals?.loading || visible,
  ]);

  return (
    <>
      {loading && (
        <div className="fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 z-[1000] bg-[#00000070] opacity-80">
          <RotatingLines
            visible={true}
            height="120"
            width="120"
            strokeColor="white"
            strokeWidth="5"
            animationDuration="0.5"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
    </>
  );
};

export default Loader;
