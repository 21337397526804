import React, { useState } from "react";
import {
  FiBarChart,
  FiChevronDown,
  FiChevronsRight,
  FiHome,
  FiUsers,
  FiFolder,
} from "react-icons/fi";
import { Logo as CompanyLogo } from "../../assets/icons/icons";
import { motion } from "framer-motion";
import Option from "./SideBarOption";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SideBar = () => {
  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <div className="flex bg-indigo-50">
      <motion.nav
        layout
        className="sticky top-0 h-screen shrink-0 border-r border-slate-300 bg-white p-2"
        style={{
          width: open ? "225px" : "fit-content",
        }}
      >
        {/* Title Section */}
        <div className="mb-3 border-b border-slate-300 pb-3">
          <div className="flex cursor-pointer items-center justify-between rounded-md transition-colors hover:bg-slate-100">
            <div className="flex items-center gap-2">
              <motion.div
                layout
                className="grid size-10 shrink-0 place-content-center rounded-md bg-white"
              >
                <img src={CompanyLogo} alt="Company Logo" />
              </motion.div>
              {open && (
                <motion.div
                  layout
                  initial={{ opacity: 0, y: 12 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.125 }}
                >
                  <span className="block text-s font-semibold">
                    {user?.company_name}
                  </span>
                  <span className="block text-s text-slate-500">
                    {user?.plan_name} Plan
                  </span>
                </motion.div>
              )}
            </div>
            {open && <FiChevronDown className="mr-2" />}
          </div>
        </div>

        {/* Navigation Options */}
        <div className="space-y-1">
          <Link to="/admin/dashboard">
            <Option
              Icon={FiHome}
              title="Dashboard"
              selected={selected}
              setSelected={setSelected}
              open={open}
            />
          </Link>
          <Link to="/admin/user-management">
            <Option
              Icon={FiUsers}
              title="Users"
              selected={selected}
              setSelected={setSelected}
              open={open}
            />
          </Link>
          <Link to="/admin/analytics">
            <Option
              Icon={FiBarChart}
              title="Analytics"
              selected={selected}
              setSelected={setSelected}
              open={open}
            />
          </Link>
          <Link to="/projects">
            <Option
              Icon={FiFolder}
              title="Home"
              selected={selected}
              setSelected={setSelected}
              open={open}
            />
          </Link>
        </div>

        {/* Toggle Button */}
        <motion.button
          layout
          onClick={() => setOpen((pv) => !pv)}
          className="absolute bottom-0 left-0 right-0 border-t border-slate-300 transition-colors hover:bg-slate-100"
        >
          <div className="flex items-center p-2">
            <motion.div
              layout
              className="grid size-10 place-content-center text-lg"
            >
              <FiChevronsRight
                className={`transition-transform ${open && "rotate-180"}`}
              />
            </motion.div>
            {open && (
              <motion.span
                layout
                initial={{ opacity: 0, y: 12 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.125 }}
                className="text-xs font-medium"
              >
                Hide
              </motion.span>
            )}
          </div>
        </motion.button>
      </motion.nav>
    </div>
  );
};

export default SideBar;
