export const constants = {
  deleteProjectHeading: "Are you sure you want to delete this project?",
  deleteSubHeading: "This action cannot be undone.",
  deleteProjectFileHeading: "Are you sure you want to delete this file?",
};

export const tempAnnotations = {
  silt_fence: {
    total_detected_objects: 34,
    detections: [
      {
        page_num: 1,
        coordinates: [
          { x: 100, y: 150 },
          { x: 200, y: 250 },
          { x: 150, y: 300 },
        ],
      },
      {
        page_num: 3,
        coordinates: [
          { x: 100, y: 150 },
          { x: 200, y: 250 },
          { x: 150, y: 300 },
        ],
      },
    ],
  },
  rock_berm: {
    total_detected_objects: 24,
    detections: [
      {
        page_num: 2,
        coordinates: [
          { x: 100, y: 150 },
          { x: 200, y: 250 },
          { x: 150, y: 300 },
        ],
      },
      {
        page_num: 4,
        coordinates: [
          { x: 100, y: 150 },
          { x: 200, y: 250 },
          { x: 150, y: 300 },
        ],
      },
    ],
  },
};

export const proposalValidityDays = [15, 30, 60, 90];
