import React, { useState } from "react";
import {
  CheckCircleGreen,
  PreferenceSetting,
  PreferencesIcon,
} from "../../assets/icons/icons";
import OnboardingTooltip from "../../components/OnboardingTooltip";
import { onBoardingAssistance } from "../../utils/constants/appConstants";

const ProjectPreference = ({ formik, setShowPreferenceModal }) => {
  const [showTooltip, setShowTooltip] = useState(
    !localStorage.getItem("tooltip_shown_set_preferences")
  );

  const handleTooltipDismiss = () => {
    localStorage.setItem("tooltip_shown_set_preferences", "true");
    setShowTooltip(false);
  };

  return (
    <>
      <div className="w-[46%] bg-[#F5F5F5] rounded-xl p-5 mb-4 overflow-y-scroll">
        {formik.values.preference.items.length > 0 &&
        formik.values.preference.unitMeasurements.length > 0 &&
        formik.values.preference.unitOfAcreage.length > 0 ? (
          <>
            <div className="w-full flex items-center gap-3">
              <img src={PreferenceSetting} alt="PreferenceSetting" />
              <div className="text-[#18181B] text-xl font-medium">
                Preferences
              </div>
            </div>
            <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
              <div className="text-[#18181B] text-lg font-medium">
                Items of Interest
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                {formik.values.preference.items.map((item, index) => (
                  <div
                    className="w-full flex items-center gap-2 text-[#18181B]"
                    key={index}
                  >
                    <img
                      className="w-5 h-5"
                      src={CheckCircleGreen}
                      alt="CheckCircleGreen"
                    />
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
              <div className="text-[#18181B] text-lg font-medium">
                Units of Measurement
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                {formik.values.preference.unitMeasurements.map(
                  (unit, index) => (
                    <div
                      className="w-full flex items-center gap-2 text-[#18181B]"
                      key={index}
                    >
                      <img
                        className="w-5 h-5"
                        src={CheckCircleGreen}
                        alt="CheckCircleGreen"
                      />
                      {unit}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
              <div className="text-[#18181B] text-lg font-medium">
                Acreage Units
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                {formik.values.preference.unitOfAcreage.map((unit, index) => (
                  <div
                    className="w-full flex items-center gap-2 text-[#18181B]"
                    key={index}
                  >
                    <img
                      className="w-5 h-5"
                      src={CheckCircleGreen}
                      alt="CheckCircleGreen"
                    />
                    {unit}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full py-5">
              <div className="text-[#18181B] text-lg font-medium">
                Langitude and Latitude
              </div>
              <div className="w-full text-[#18181B] mt-3">
                {formik.values.preference.longitude} -{" "}
                {formik.values.preference.latitude}
              </div>
            </div>
          </>
        ) : (
          <div className="w-full h-full grid flex-grow justify-center items-center relative">
            <img
              src={PreferencesIcon}
              alt="PreferencesIcon"
              className="mb-4 m-auto"
            />
            <div className="relative">
              <button
                type="button"
                className="h-9 bg-black text-[14px] text-white px-4 rounded-md mb-auto"
                onClick={() => setShowPreferenceModal(true)}
              >
                Set Preferences
              </button>

              {showTooltip && (
                <OnboardingTooltip
                  message={onBoardingAssistance.setPreferences}
                  onClose={handleTooltipDismiss}
                  className={
                    "absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-sm rounded-md shadow-lg p-3 w-[200px] animate-fade-in"
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectPreference;
