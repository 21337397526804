import { combineReducers } from 'redux';
import authReducer from '../redux/slices/authSlice';
import planReducer from '../redux/slices/planSlice';
import preferenceReducer from "../redux/slices/preferenceSlice";
import projectReducer from "../redux/slices/projectSlice";
import userManagementReducer from "../redux/slices/userManagementSlice";
import contactReducer from "../redux/slices/contactSlice";
import adminReducer from "../redux/slices/adminSlice";
import proposalReducer from "../redux/slices/proposalSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  plans: planReducer,
  preferences: preferenceReducer,
  projects: projectReducer,
  user_management: userManagementReducer,
  contact: contactReducer,
  admin: adminReducer,
  proposals: proposalReducer,
});

export default rootReducer;
