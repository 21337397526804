// ExpiredLicenseFallback.jsx
import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const ExpiredLicenseFallback = ({ onRetry }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4"
      >
        <h2 className="text-2xl font-bold text-[#18181B] mb-4">
          Your free trial for this API has expired.
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          Please contact our sales team for subscription options.
        </p>
        {onRetry && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onRetry}
            className="bg-black text-white px-6 py-2 rounded-md"
          >
            Retry
          </motion.button>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default ExpiredLicenseFallback;
