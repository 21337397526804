import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSubscriptions } from "../../redux/slices/planSlice";
import { ActiveIcon, DeleteIcon } from "../../assets/icons/icons";
import { capitalize, TABLE_ROWS_PER_PAGE } from "../../utils/mainHelper";
import CancelSubscriptionConfirmationModal from "./cancelSubscriptionConfirmationModal";
import { hasAccess } from "../../utils/authHelper";

const UserSubscriptions = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const { subscriptions, currentPage, totalPages } = useSelector(
    (state) => state.plans
  );
  const { userRoles } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUserSubscriptions({ page: 1, perPage: TABLE_ROWS_PER_PAGE }));
  }, [dispatch]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(
        getUserSubscriptions({
          page: currentPage + 1,
          perPage: TABLE_ROWS_PER_PAGE,
        })
      );
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(
        getUserSubscriptions({
          page: currentPage - 1,
          perPage: TABLE_ROWS_PER_PAGE,
        })
      );
    }
  };

  const shouldShowDeleteButton = (subscription) => {
    return (
      subscription.status === "active" &&
      subscription.stripe_subscription_id &&
      hasAccess(userRoles)
    );
  };

  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="font-inter text-[24px] font-bold text-[#18181B] mb-6">
        My Subscriptions
      </h1>
      {subscriptions.length === 0 ? (
        <p className="text-center">You have no active subscriptions.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Plan Name
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Price
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Status
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Renewal Date
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Cancelled Date
                </th>
                <th className="py-3 px-4 text-center text-sm font-semibold text-gray-600 border-b">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((subscription) => (
                <tr key={subscription.id} className="hover:bg-gray-50">
                  <td className="py-3 px-4 text-gray-700 border-b">
                    {subscription.name}
                  </td>
                  <td className="py-3 px-4 text-gray-700 border-b">
                    ${subscription.price}/month
                  </td>
                  <td className="py-3 px-4 text-gray-700 border-b capitalize">
                    {subscription.status === "active" ? (
                      <img src={ActiveIcon} alt="Active" />
                    ) : (
                      <span className="text-red-600">
                        {capitalize(subscription.status)}
                      </span>
                    )}
                  </td>
                  <td className="py-3 px-4 text-gray-700 border-b">
                    {subscription.renews_at
                      ? new Date(subscription.renews_at).toLocaleDateString()
                      : "-"}
                  </td>
                  <td className="py-3 px-4 text-gray-700 border-b">
                    {subscription.cancelled_at
                      ? new Date(subscription.cancelled_at).toLocaleDateString()
                      : "-"}
                  </td>
                  <td className="py-3 px-4 text-center border-b">
                    {shouldShowDeleteButton(subscription) && (
                      <button onClick={() => setShowModal(true)}>
                        <img src={DeleteIcon} alt="DeleteIcon" />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between items-center py-3">
            <p className="font-inter text-[14px] font-normal leading-[20px] text-[#71717A]">
              Page {totalPages === 0 ? 0 : currentPage} of {totalPages}
            </p>

            <div className="flex space-x-3">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages || totalPages === 0}
                className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <CancelSubscriptionConfirmationModal
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default UserSubscriptions;
