import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SuccessModal from "./subscription/success";
import { OverviewImg } from "../assets/images/images";
import { FilterProjectIcon } from "../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGetProjects,
  handleTrackProjectOpened,
} from "../redux/slices/projectSlice";
import { PROJECTS_PER_PAGE } from "../utils/mainHelper";
import { FaTh, FaThList } from "react-icons/fa";
import ProjectsView from "../components/projects/ProjectsView";
import { handleApiError } from "../utils/errorHandler";
import OnboardingTooltip from "../components/OnboardingTooltip";
import { onBoardingAssistance } from "../utils/constants/appConstants";
import { hasAccess } from "../utils/authHelper";

const Home = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const payment = new URLSearchParams(location.search).get("payment");
  const plan = new URLSearchParams(location.search).get("plan");

  const { isSubscribed, userRoles } = useSelector((state) => state.auth);
  const { data, currentPage, totalPages } = useSelector(
    (state) => state.projects
  );

  const [showCreateNowTooltip, setShowCreateNowTooltip] = useState(false);
  const [isGridView, setIsGridView] = useState(() => {
    return localStorage.getItem("viewMode") === "grid";
  });

  useEffect(() => {
    const hasSeenTooltip = localStorage.getItem("hasSeenCreateNowTooltip");
    if (!hasSeenTooltip) {
      setShowCreateNowTooltip(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("viewMode", isGridView ? "grid" : "list");
  }, [isGridView]);

  useEffect(() => {
    dispatch(
      handleGetProjects({ page: currentPage, perPage: PROJECTS_PER_PAGE })
    )
      .unwrap()
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  }, [dispatch, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(
        handleGetProjects({ page: currentPage + 1, perPage: PROJECTS_PER_PAGE })
      );
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(
        handleGetProjects({ page: currentPage - 1, perPage: PROJECTS_PER_PAGE })
      );
    }
  };

  const handleOpenProject = (id) => {
    dispatch(handleTrackProjectOpened(id))
      .unwrap()
      .then(() => navigate(`/projects/${id}`))
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCloseCreateNowTooltip = () => {
    localStorage.setItem("hasSeenCreateNowTooltip", "true");
    setShowCreateNowTooltip(false);
  };

  return (
    <>
      <div className="w-[92%] mx-auto">
        {/* Heading */}
        <h1 className="font-inter text-[24px] font-bold text-[#18181B] mb-4">
          All Projects
        </h1>

        {/* Start New Project Section */}
        <div
          className="h-[200px] flex items-center justify-start pl-12 rounded rounded-[10px] relative"
          style={{
            backgroundImage: `url(${OverviewImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="text-left">
            <h2 className="font-inter text-[24px] font-semibold text-[#18181B] mb-4">
              Start new Project
            </h2>
            <Link to="/projects/new" className="text-gray-700">
              <button
                disabled={!hasAccess(userRoles)}
                className={`
                      ${
                        !hasAccess(userRoles)
                          ? "bg-gray-300 text-black cursor-not-allowed"
                          : "bg-black text-white cursor-pointer"
                      } 
                      text-[14px] px-4 py-2 rounded-[7px]
                    `}
              >
                Create Now
              </button>
            </Link>

            {hasAccess(userRoles) && showCreateNowTooltip && (
              <OnboardingTooltip
                message={onBoardingAssistance.createProject}
                onClose={handleCloseCreateNowTooltip}
                className={
                  "absolute top-[10px] left-20 bg-gray-800 text-white text-sm rounded-md shadow-lg p-3 animate-fade-in max-w-[300px]"
                }
              />
            )}
          </div>
        </div>

        {/* Recent projects heading */}
        <div className="flex justify-between items-center py-4">
          <h1 className="font-inter text-[1.5rem] font-bold text-[#18181B]">
            Recent Projects
          </h1>
          <div className="flex">
            <button
              onClick={() => setIsGridView(!isGridView)}
              className="p-2 rounded hover:bg-gray-200"
            >
              {isGridView ? <FaThList size={25} /> : <FaTh size={25} />}
            </button>
            <button className="p-2 rounded hover:bg-gray-200">
              <img src={FilterProjectIcon} alt="FilterProjectIcon" />
            </button>
          </div>
        </div>

        {/* Recent projects list */}
        <ProjectsView
          data={data}
          handleOpenProject={handleOpenProject}
          isGridView={isGridView}
        />

        <div className="flex justify-between items-center py-12">
          <p className="font-inter text-[14px] font-normal leading-[20px] text-[#71717A]">
            Page {totalPages === 0 ? 0 : currentPage} of {totalPages}
          </p>

          <div className="flex space-x-3">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
            >
              Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages || totalPages === 0}
              className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {plan && (
        <SuccessModal
          isOpen={payment === "success" && isSubscribed}
          planName={plan}
        />
      )}
    </>
  );
};

export default Home;
