import React, { useEffect, useRef, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { useLocation } from "react-router-dom";
import { tempAnnotations } from "../../utils/constants/generalConstants";
import ExpiredLicenseFallback from "../../components/projects/ExpiredLicenseFallback";

const EditProjectFile = () => {
  const viewer = useRef(null);
  const location = useLocation();
  const file = location.state?.file;
  const [demoKeyExpired, setDemoKeyExpired] = useState(false);

  const viewerPath =
    process.env.NODE_ENV === "production"
      ? "https://www.verixpro.com/WebViewer/lib"
      : "/WebViewer/lib";

  console.log("initial document", file, file?.url);

  useEffect(() => {
    WebViewer(
      {
        path: viewerPath,
        licenseKey:
          "demo:1738672280444:616e5a2503000000005766d7bf9358e89cee53c706e9a212ba51e84fa0",
        initialDoc: file?.url,
      },
      viewer.current
    )
      .then((instance) => {
        const { documentViewer, annotationManager, Annotations } =
          instance.Core;
        instance.UI.enableFeatures([instance.UI.Feature.ContentEdit]);

        documentViewer.addEventListener("documentLoaded", async () => {
          try {
            // Load existing annotations from stored data
            const storedAnnotations = localStorage.getItem(
              `annotations_${file?.id}_${file?.filename}`
            );
            if (storedAnnotations) {
              const xfdfString = JSON.parse(storedAnnotations);
              await annotationManager.importAnnotations(xfdfString);
            }

            // Load predefined annotations
            Object.keys(tempAnnotations).forEach((key) => {
              const item = tempAnnotations[key];
              item.detections.forEach(({ page_num, coordinates }) => {
                const annot = new Annotations.PolygonAnnotation({
                  PageNumber: page_num,
                  StrokeColor:
                    key === "silt_fence"
                      ? new Annotations.Color(0, 255, 0, 1) // Green for silt_fence
                      : new Annotations.Color(255, 0, 0, 1), // Red for rock_berm
                });

                coordinates.forEach(({ x, y }) => annot.addPathPoint(x, y));
                annotationManager.addAnnotation(annot);
                annotationManager.redrawAnnotation(annot);
              });
            });

            // Save annotations when modified
            annotationManager.addEventListener(
              "annotationChanged",
              async () => {
                const xfdfString = await annotationManager.exportAnnotations();
                localStorage.setItem(
                  `annotations_${file?.id}_${file?.filename}`,
                  JSON.stringify(xfdfString)
                );
              }
            );
          } catch (error) {
            console.error("Error loading annotations:", error);
          }
        });
      })
      .catch((error) => {
        console.error("WebViewer initialization failed:", error);
        setDemoKeyExpired(true);
        if (error.message.includes("license")) {
          console.error("License expired", error);
          setDemoKeyExpired(true);
        }
      });
  }, [file]);

  const handleRetry = () => {
    setDemoKeyExpired(false);
    window.location.reload();
  };

  return (
    <div className="MyComponent">
      {demoKeyExpired ? (
        <ExpiredLicenseFallback onRetry={handleRetry} />
      ) : (
        <div
          className="webviewer"
          ref={viewer}
          style={{ height: "100vh" }}
        ></div>
      )}
    </div>
  );
};

export default EditProjectFile;
