import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { handleGetAnalytics } from "../../../redux/slices/adminSlice";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../../../utils/errorHandler";

const AnalyticsTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { analytics } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(handleGetAnalytics())
      .unwrap()
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  }, [dispatch]);

  return (
    <motion.div
      className="p-6 space-y-6"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-semibold text-gray-800">Analytics</h2>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {analytics?.map((stat, index) => (
          <motion.div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center space-y-2 border border-gray-200"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <h3 className="text-lg font-medium text-gray-600 text-center">
              {stat.title}
            </h3>
            <motion.p
              className="text-3xl font-bold text-blue-500"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1.1 }}
            >
              {stat.count}
            </motion.p>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default AnalyticsTab;
