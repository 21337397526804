import React, { useState, useEffect, useRef } from "react";
import { projects } from "../../utils/projects/projectConstants";
import { ProjectOptions } from "../../assets/icons/icons";
import { timeAgo } from "../../utils/timeAgo";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ProjectsGridView = ({
  data,
  handleOpenProject,
  handleOpenRenameModal,
  handleOpenDeleteModal,
  duplicateProject,
  createProposal,
}) => {
  const [activeProject, setActiveProject] = useState(null);
  const optionsRef = useRef(null);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (optionsRef.current && !optionsRef.current.contains(e.target)) {
        setActiveProject(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionsClick = (e, projectId) => {
    e.stopPropagation();
    setActiveProject(activeProject === projectId ? null : projectId);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {data.map((project) => (
        <div
          key={project.id}
          className="border border-[#E4E4E7] rounded-[8px] shadow-md hover:shadow-lg relative"
        >
          {/* Project Image */}
          <Link
            onClick={() => handleOpenProject(project.id)}
            to={`/projects/${project.id}`}
          >
            <img
              src={project?.project_image_url || projects[project.id % 6].image}
              alt={project.name}
              className="w-full max-h-[210px] h-[65%] object-cover"
            />
          </Link>
          <div className="py-4 px-6 flex justify-between h-[35%]">
            <Link
              onClick={() => handleOpenProject(project.id)}
              to={`/projects/${project.id}`}
            >
              <div className="flex flex-col mb-3">
                <h3 className="font-inter text-[1rem] font-semibold text-[#18181B] mb-2">
                  {project.name}
                </h3>
                <p className="text-[#71717A] text-[16px] mb-1">340KB | pdf</p>
                <p className="text-[#71717A] text-[16px]">
                  Opened {timeAgo(project.opened_at || project.updated_at)}
                </p>
              </div>
            </Link>

            {/* Options Button */}
            <div className="flex items-start relative">
              <button
                className="text-[#71717A] p-2 hover:bg-[#f0f0f5] rounded-full"
                onClick={(e) => handleOptionsClick(e, project.id)}
              >
                <img src={ProjectOptions} alt="ProjectOptions" />
              </button>

              {/* Dropdown Menu */}
              {activeProject === project.id && (
                <div
                  ref={optionsRef}
                  className="absolute right-0 mt-2 bg-white border border-[#E4E4E7] rounded-lg shadow-lg w-48 z-10"
                  style={{
                    top: 20, // Ensure it appears below the button
                    right: 20, // Position dropdown directly to the right of the button
                    marginLeft: "8px", // Optional: adds space between the button and the dropdown
                  }}
                >
                  <ul className="text-sm text-[#18181B]">
                    <li
                      onClick={() => handleOpenProject(project.id)}
                      className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                    >
                      Open
                    </li>
                    {project.user_id === user.id && (
                      <>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            handleOpenRenameModal(e, project.id);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Rename
                        </li>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            handleOpenDeleteModal(e, project.id);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Delete
                        </li>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            duplicateProject(e, project);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Duplicate
                        </li>
                        <li
                          onClick={(e) => {
                            setActiveProject(null);
                            createProposal(e, project);
                          }}
                          className="px-4 py-2 hover:bg-[#f0f0f5] cursor-pointer"
                        >
                          Create Proposal
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectsGridView;
