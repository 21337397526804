import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { calculateSubtotal } from "../utils/mainHelper";

const PreviewProposal = ({ showModal, setShowModal, values, sigPad }) => {
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white rounded-lg shadow-lg p-6 w-[90%] md:w-[60%] lg:w-[50%] max-h-[90vh] overflow-y-auto relative"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
          >
            {/* Close Button */}
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              ✖
            </button>

            {/* Invoice Header */}
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800">Invoice</h2>
              <p className="text-sm text-gray-500">
                {values.date || "Date: Not Set"}
              </p>
            </div>

            {/* Proposal Details */}
            <div className="mb-4">
              <p className="font-semibold text-gray-700">
                From: {values.proposalFrom}
              </p>
              <p className="font-semibold text-gray-700">
                To: {values.billTo || "Not Set"}
              </p>
              <p className="text-gray-600">
                Payment Terms: {values.paymentTerms || "N/A"}
              </p>
              <p className="text-gray-600">
                Valid For {values.dueDate || "N/A"} Days
              </p>
            </div>

            {/* Items Table */}
            <div className="mt-4">
              <table className="w-full border-collapse border border-gray-300">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Item
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Qty
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Unit
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Price
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-left">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.items.map((item, index) => (
                    <tr key={index} className="border border-gray-300">
                      <td className="border border-gray-300 px-4 py-2">
                        {item.item || "N/A"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {item.quantity || 0}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        {item.unit || "N/A"}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        ${item.price || 0}
                      </td>
                      <td className="border border-gray-300 px-4 py-2">
                        ${item.quantity * item.price || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Subtotal & Total */}
            <div className="mt-4 text-right">
              <p className="text-lg font-semibold">
                Subtotal: ${calculateSubtotal(values.items)}
              </p>
              <p className="text-xl font-bold text-gray-800">
                Total: ${calculateSubtotal(values.items)}
              </p>
            </div>

            {/* Notes & Terms */}
            <div className="mt-4">
              <p className="font-semibold">Notes:</p>
              <p className="text-gray-600">
                {values.notes || "No additional notes"}
              </p>
            </div>

            <div className="mt-4">
              <p className="font-semibold">Terms & Conditions:</p>
              <p className="text-gray-600">
                {values.terms || "No terms specified"}
              </p>
            </div>

            {/* Signature */}
            {sigPad && (
              <div className="mt-6">
                <p className="font-semibold">Signature:</p>
                {sigPad.current && !sigPad.current.isEmpty() ? (
                  <img
                    src={sigPad.current.toDataURL()}
                    alt="Signature"
                    className="w-40 mt-2 rounded-md"
                  />
                ) : (
                  <p className="text-gray-500">No signature provided</p>
                )}
              </div>
            )}

            {/* Close Button */}
            <div className="mt-6 text-center">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-800 text-white px-6 py-2 rounded-md transition-all hover:bg-gray-900"
              >
                Close
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PreviewProposal;
