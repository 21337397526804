import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import proposalService from "../services/proposalService";
import { showAllNotifications } from "../../utils/notificationHelper";
import { notifications } from "../../utils/notificationConstants";
import ToastColors from "../../constants/toastColors";

export const handleCreateProposal = createAsyncThunk(
  "proposals/handleCreateProposal",
  async (data, thunkAPI) => {
    try {
      const response = await proposalService.createProposal(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleGetProposals = createAsyncThunk(
  "proposals/handleGetProposals",
  async ({ filter, page, perPage }, thunkAPI) => {
    try {
      const response = await proposalService.getProposals(
        filter,
        page,
        perPage
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const proposalSlice = createSlice({
  name: "proposals",
  initialState: {
    proposals: [],
    pagination: { totalPages: 1, currentPage: 1 },
    filter: "my_proposals",
    loading: false,
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.pagination.currentPage = 1;
    },
    setCurrentPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCreateProposal.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleCreateProposal.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        showAllNotifications(
          notifications.proposalCreatedSuccessfully,
          ToastColors.success
        );
      })
      .addCase(handleCreateProposal.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(handleGetProposals.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetProposals.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.proposals = action.payload.proposals;
        state.pagination = action.payload.pagination;
      })
      .addCase(handleGetProposals.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilter, setCurrentPage } = proposalSlice.actions;
export default proposalSlice.reducer;
