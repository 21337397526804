import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";
import { hasAccess } from "../utils/authHelper";
import { showAllNotifications } from "../utils/notificationHelper";
import Sidebar from "../components/admin/SideBar";
import { STATUSES } from "../constants/statuses";

const AdminRoute = ({ component: Component }) => {
  const { isAuthenticated, userRoles, status } = useSelector(
    (state) => state.auth
  );

  if (status === STATUSES.LOADING) return null;

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!hasAccess(userRoles)) {
    showAllNotifications(
      "You are not authorised to access the requested resource"
    );
    return <Navigate to="/projects" />;
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-6">
        <Component />
      </div>
    </div>
  );
};

export default AdminRoute;
