import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  CircularProgress,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  handleGetProposals,
  setFilter,
  setCurrentPage,
} from "../../redux/slices/proposalSlice";
import { handleApiError } from "../../utils/errorHandler";
import PreviewProposal from "../../components/PreviewProposal";

const Proposals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);

  const { proposals, pagination, filter, loading } = useSelector(
    (state) => state.proposals
  );

  useEffect(() => {
    dispatch(
      handleGetProposals({ filter, page: pagination.currentPage, perPage: 5 })
    )
      .unwrap()
      .catch((error) => handleApiError(error, dispatch, navigate));
  }, [filter, pagination.currentPage, dispatch]);

  const handleSelectProposal = (proposal) => {
    const data = {
      proposalFrom: proposal.from,
      billTo: proposal.bill_to,
      date: proposal.date,
      paymentTerms: proposal.payment_terms,
      dueDate: proposal.due_date,
      items: proposal.items,
      notes: proposal.notes,
      terms: proposal.terms,
    };
    setSelectedProposal(data);
    setShowModal(true);
  };

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800">Proposals</h2>

      <PreviewProposal
        showModal={showModal}
        setShowModal={setShowModal}
        values={selectedProposal}
      />

      {/* Dropdown Filter (Matches AdminDashboard Style) */}
      <div className="flex justify-end">
        <Select
          value={filter}
          onChange={(e) => dispatch(setFilter(e.target.value))}
          className="border rounded-md p-2 text-gray-700"
        >
          <MenuItem value="my_proposals">My Proposals</MenuItem>
          <MenuItem value="proposals_on_my_projects">
            Proposals on My Projects
          </MenuItem>
        </Select>
      </div>

      {/* Proposals Table */}
      <TableContainer
        component={Paper}
        className="mt-4 shadow-md rounded-lg overflow-hidden"
      >
        {loading ? (
          <Box className="flex justify-center items-center h-48">
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Project Name
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Bill To
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Date
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Total Amount
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Invoice
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proposals.length > 0 ? (
                proposals.map((proposal) => (
                  <TableRow
                    key={proposal.id}
                    className="border-b hover:bg-gray-100"
                    onClick={() => handleSelectProposal(proposal)}
                  >
                    <TableCell className="py-3 px-4">
                      {proposal.project?.name || "N/A"}
                    </TableCell>
                    <TableCell className="py-3 px-4">
                      {proposal.bill_to}
                    </TableCell>
                    <TableCell className="py-3 px-4">{proposal.date}</TableCell>
                    <TableCell className="py-3 px-4">
                      ${proposal.total_amount}
                    </TableCell>
                    <TableCell className="py-3 px-4">
                      <a
                        href={proposal.invoice_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View Invoice
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center py-4">
                    No proposals found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-end mt-4">
        <Pagination
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={(event, page) => dispatch(setCurrentPage(page))}
        />
      </div>
    </div>
  );
};

export default React.memo(Proposals);
