import React, { useEffect } from "react";
import { LoginImg, LogoImg } from "../../assets/images/images";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { RegisterSchema } from "../../utils/schema";
import { handleSignup } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

const Register = () => {
	const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) navigate("/projects");
  }, [isAuthenticated, navigate]);

	const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    password: "",
    confirmPassword: "",
    role: "Estimator",
  };

	const handleRegister = (values) => {
    dispatch(handleSignup({
      data: {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          company: values.company,
          password: values.password,
          password_confirmation: values.confirmPassword,
          role: values.role,
        }
      },
      navigate
    }));
	};

	return (
		<div
			className="w-full h-[100vh] px-12 py-14"
			style={{
				backgroundImage: `url(${LoginImg})`,
				backgroundSize: "107%",
				backgroundPositionX: "right",
				backgroundPositionY: "top",
			}}
		>
			<div className="w-full">
				<img src={LogoImg} alt="VerixPro" />
			</div>
			<div className="w-full flex justify-center items-center">
				<div className="w-full max-w-[660px] mt-16">
					<div className="w-full text-2xl font-semibold mb-5">
						Create your account
					</div>
					<div className="w-full mb-8">
						Enter details to create your account
					</div>
					<Formik
						initialValues={initialValues}
						validationSchema={RegisterSchema}
						onSubmit={handleRegister}
					>
						{({ isSubmitting }) => (
							<Form className="w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="firstName"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Enter your first name"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="lastName"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Enter your last name"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="email"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                    />
                    <ErrorMessage
                      name="email"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="phone"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Enter your phone no"
                    />
                    <ErrorMessage
                      name="phone"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                </div>
								<div className="w-full mb-5">
									<label
										className="w-full text-sm font-medium mb-2"
										htmlFor="company"
									>
										Company <span className="text-red-500">*</span>
									</label>
									<Field
										className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
										id="company"
										name="company"
										type="text"
										placeholder="Enter your company"
									/>
									<ErrorMessage
										name="company"
										component="p"
										className="text-red-500 text-xs italic"
									/>
								</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="password"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="w-full text-sm font-medium mb-2"
                      htmlFor="confirmPassword"
                    >
                      Confirm Password <span className="text-red-500">*</span>
                    </label>
                    <Field
                      className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="Enter confirm password"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="p"
                      className="text-red-500 text-xs italic"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-black text-white text-sm h-10 flex justify-center items-center rounded-md"
                    disabled={isSubmitting}
                  >
                    Sign up
                  </button>
                </div>
							</Form>
						)}
					</Formik>
					<div className="w-full">
						<Link to="/signin">
							Already have account? <span className="font-bold">Sign in</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Register;
