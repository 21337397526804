import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import adminService from "../services/adminService";

export const handleGetActivityLogs = createAsyncThunk(
  "admin/handleGetActivityLogs",
  async ({ actionType, userEmail, page }, thunkAPI) => {
    try {
      const response = await adminService.getActivityLogs({
        params: {
          action_type: actionType,
          user_email: userEmail,
          page,
          per_page: 10,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Something went wrong"
      );
    }
  }
);

export const handleGetAnalytics = createAsyncThunk(
  "admin/handleGetAnalytics",
  async (_, thunkAPI) => {
    try {
      const response = await adminService.getAnalytics();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    activity_logs: [],
    analytics: [],
    status: STATUSES.IDLE,
    error: null,
    loading: false,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleGetActivityLogs.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetActivityLogs.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.activity_logs = action.payload.logs;
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(handleGetActivityLogs.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(handleGetAnalytics.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetAnalytics.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.analytics = action.payload;
      })
      .addCase(handleGetAnalytics.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage } = adminSlice.actions;
export default adminSlice.reducer;
