import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle } from "react-icons/fi";

const DeleteModal = ({ heading, subHeading, onClose, onConfirm }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        className="bg-black/50 backdrop-blur-sm p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
      >
        <motion.div
          initial={{ scale: 0.95, rotate: "5deg" }}
          animate={{ scale: 1, rotate: "0deg" }}
          exit={{ scale: 0.95, rotate: "0deg" }}
          onClick={(e) => e.stopPropagation()}
          className="bg-white text-black p-6 rounded-lg w-full max-w-lg shadow-2xl cursor-default relative overflow-hidden"
        >
          <div className="relative z-10">
            <div className="bg-red-600 w-16 h-16 mb-2 rounded-full text-3xl text-white grid place-items-center mx-auto">
              <FiAlertCircle />
            </div>
            <h3 className="text-2xl font-semibold text-center mb-2">
              {heading}
            </h3>
            <p className="text-center mb-6 text-gray-600 dark:text-gray-400">
              {subHeading}
            </p>
            <div className="flex gap-2">
              <button
                onClick={onClose}
                className="bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 transition-colors text-gray-800 dark:text-white font-medium w-full py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className="bg-red-600 hover:bg-red-700 transition-colors text-white font-medium w-full py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default DeleteModal;
