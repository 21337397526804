import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Login from "../pages/authentication/login";
import Register from "../pages/authentication/register";
import ResetPassword from "../pages/authentication/resetPassword";
import NewPassword from "../pages/authentication/newPassword";
import VerifyEmail from "../pages/authentication/verifyEmail";
import Home from "../pages/home";
import Plans from "../pages/subscription/plans";
import NewProject from "../pages/projects/newProject";
import ProjectDetails from "../pages/projects/projectDetails";
import UserManagement from "../pages/admin/userManagement";
import UserSubscriptions from "../pages/subscription/userSubscriptions";
import ProjectFiles from "../pages/projects/projectFiles";
import LandingPage from "../pages/verixpro/landingPage";
import Pricing from "../pages/verixpro/pricing";
import FAQ from "../pages/verixpro/faq";
import AboutUs from "../pages/verixpro/aboutUs";
import AdminDashboard from "../pages/admin/Dashboard/adminDashboard";
import EditProjectFile from "../pages/projects/editProjectFile";
import AnalyticsTab from "../pages/admin/Dashboard/analyticsTab";
import CreateProposal from "../pages/projects/createProposal";
import Proposals from "../pages/proposals/Proposals";

const AppRouter = () => {
  const PUBLIC_ROUTES = [
    { path: "/", component: <LandingPage /> },
    { path: "/pricing", component: <Pricing /> },
    { path: "/faq", component: <FAQ /> },
    { path: "/about-us", component: <AboutUs /> },
    { path: "/signin", component: <Login /> },
    { path: "/signup", component: <Register /> },
    { path: "/reset-password", component: <ResetPassword /> },
    { path: "/verify-email", component: <VerifyEmail /> },
    { path: "/new-password", component: <NewPassword /> },
  ];

  const PRIVATE_ROUTES = [
    { path: "/projects", component: Home },
    { path: "/plans", component: Plans },
    { path: "/subscriptions", component: UserSubscriptions },
    { path: "/projects/new", component: NewProject },
    { path: "/projects/:id", component: ProjectDetails },
    { path: "/projects/:id/files", component: ProjectFiles },
    { path: "/projects/:id/files/:file_id/edit", component: EditProjectFile },
    { path: "/projects/:id/create-proposal", component: CreateProposal },
    { path: "/proposals", component: Proposals },
  ];

  const ADMIN_ROUTES = [
    { path: "/dashboard", component: AdminDashboard },
    { path: "/user-management", component: UserManagement },
    { path: "/analytics", component: AnalyticsTab },
  ];

  return (
    <Routes>
      {PUBLIC_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PublicRoute>{r.component}</PublicRoute>}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {PRIVATE_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PrivateRoute component={r.component} />}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {ADMIN_ROUTES.map((r, i) => (
        <Route
          path={`/admin${r.path}`}
          element={<AdminRoute component={r.component} />}
          key={`${r.path}-${i + 1}`}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
