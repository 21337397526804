import React from "react";
import { motion } from "framer-motion";

const OnboardingTooltip = ({ message, onClose, className }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className={className}
    >
      <p className="mb-2">{message}</p>
      <button
        onClick={onClose}
        className="bg-white text-black px-2 py-1 rounded-md text-xs font-medium"
      >
        OK
      </button>
    </motion.div>
  );
};

export default OnboardingTooltip;
