import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { timeAgo } from "../../../utils/timeAgo";
import {
  handleGetActivityLogs,
  setCurrentPage,
} from "../../../redux/slices/adminSlice";
import { motion } from "framer-motion";
import {
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  CircularProgress,
  Box,
} from "@mui/material";
import { handleApiError } from "../../../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activity_logs, currentPage, totalPages, loading } = useSelector(
    (state) => state.admin
  );
  const [actionType, setActionType] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    dispatch(
      handleGetActivityLogs({ actionType, userEmail, page: currentPage })
    )
      .unwrap()
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  }, [actionType, userEmail, currentPage, dispatch]);

  const handleActionTypeChange = (e) => {
    setActionType(e.target.value);
    dispatch(setCurrentPage(1));
  };

  const handleSetUserEmail = (e) => {
    setUserEmail(e.target.value);
    dispatch(setCurrentPage(1));
  };

  return (
    <motion.div
      className="p-6 space-y-6"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-semibold text-gray-800">
        User Activity Overview
      </h2>

      {/* Filters */}
      <div className="flex gap-4 mt-4">
        <Select
          value={actionType}
          onChange={handleActionTypeChange}
          displayEmpty
          className="w-48"
          variant="outlined"
        >
          <MenuItem value="">All Actions</MenuItem>
          <MenuItem value="Read">Read</MenuItem>
          <MenuItem value="Create">Create</MenuItem>
          <MenuItem value="Update">Update</MenuItem>
          <MenuItem value="Delete">Delete</MenuItem>
          <MenuItem value="Login">Login</MenuItem>
          <MenuItem value="Logout">Logout</MenuItem>
        </Select>
        <TextField
          label="Filter by User Email"
          variant="outlined"
          value={userEmail}
          onChange={handleSetUserEmail}
          className="w-64"
          placeholder="Enter email..."
        />
      </div>

      {/* Activity Log Table */}
      <TableContainer
        component={Paper}
        className="mt-4 shadow-md rounded-lg overflow-hidden"
      >
        {loading ? (
          <Box className="flex justify-center items-center h-48">
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Action
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Message
                </TableCell>
                <TableCell className="font-bold text-gray-700 bg-gray-100">
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activity_logs.length > 0 ? (
                activity_logs.map((log) => (
                  <motion.tr
                    key={log.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="border-b transition-all duration-300 hover:bg-gray-100"
                  >
                    <TableCell className="py-3 px-4 font-medium text-gray-800">
                      {log.action}
                    </TableCell>
                    <TableCell className="py-3 px-4 text-gray-700">
                      {log.message}
                    </TableCell>
                    <TableCell className="py-3 px-4 text-gray-600">
                      {timeAgo(log.created_at)}
                    </TableCell>
                  </motion.tr>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="text-center py-4">
                    No activity logs found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* Pagination */}
      <div className="flex justify-end mt-4">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => dispatch(setCurrentPage(page))}
        />
      </div>
    </motion.div>
  );
};

export default React.memo(AdminDashboard);
