import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { STATUSES } from '../constants/statuses';
import Header from "../components/Header";

const PrivateRoute = ({ component: Component }) => {
    const { isAuthenticated, status } = useSelector((state) => state.auth);

    if (status === STATUSES.LOADING) return null;

    return isAuthenticated ? (
      <>
        <Header />
        <Component />
      </>
    ) : (
      <Navigate to="/" />
    );
};

export default PrivateRoute;
