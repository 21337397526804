export const TABLE_ROWS_PER_PAGE = 5;
export const PROJECTS_PER_PAGE = 8;

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const camalCase = (str) =>
  str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const calculateSubtotal = (items) => {
  return items.reduce((total, item) => {
    const price = parseFloat(item.price) || 0;
    const quantity = parseFloat(item.quantity) || 0;
    return total + price * quantity;
  }, 0);
};
