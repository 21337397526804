import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { CrossIconProposal, PlusIcon } from "../../assets/icons/icons";
import { ProposalSchema } from "../../utils/schema";
import { proposalValidityDays } from "../../utils/constants/generalConstants";
import { calculateSubtotal } from "../../utils/mainHelper";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import PreviewProposal from "../../components/PreviewProposal";
import { handleCreateProposal } from "../../redux/slices/proposalSlice";
import { handleApiError } from "../../utils/errorHandler";

const CreateProposal = () => {
  const sigPad = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const project = location.state?.project;

  const initialValues = {
    proposalFrom: `${user.first_name} ${user.last_name}` || "",
    billTo: "",
    date: "",
    paymentTerms: "",
    dueDate: "",
    items: [{ item: "", quantity: "", unit: "", price: "" }],
    notes: "",
    terms: "",
  };

  const clearSignature = () => {
    sigPad.current.clear();
  };

  const createProposal = (values) => {
    const data = {
      proposal: {
        project_id: project.id,
        from: values.proposalFrom,
        bill_to: values.billTo,
        date: values.date,
        payment_terms: values.paymentTerms,
        due_date: parseInt(values.dueDate),
        notes: values.notes,
        terms: values.terms,
        items: values.items,
        total_amount: calculateSubtotal(values.items),
        signature: sigPad.current.toDataURL(),
      },
    };

    dispatch(handleCreateProposal(data))
      .unwrap()
      .then(() => {
        navigate("/projects");
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  return (
    <div className="w-[90%] mx-auto">
      {/* Page Heading */}
      <div className="flex items-center justify-between mb-5">
        <h1 className="font-inter text-[24px] font-bold text-[#18181B]">
          Create Proposal
        </h1>
      </div>

      {/* Row below heading */}
      <div className="flex items-center justify-between flex-wrap">
        <h2 className="font-inter text-[18px] font-bold leading-[36px] tracking-[-0.75px] text-[#18181B]">
          {project?.name || "Project Name"}
        </h2>

        {/* Buttons */}
        <div className="flex gap-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-[#18181B] text-white px-6 py-2 rounded-md"
          >
            Preview
          </button>
          <button className="bg-[#0E9B2D] text-white px-6 py-2 rounded-md">
            Export
          </button>
        </div>
      </div>

      {/* Form Section */}
      <Formik
        initialValues={initialValues}
        validationSchema={ProposalSchema}
        onSubmit={(values) => createProposal(values)}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="mt-10 flex flex-wrap justify-between">
            <PreviewProposal
              showModal={showModal}
              setShowModal={setShowModal}
              values={values}
              sigPad={sigPad}
            />

            <div className="w-full md:w-[75%] md:pr-4 text-left">
              {/* Input 1 */}
              <div className="mb-4 w-[60%]">
                <label
                  className="block text-sm font-medium font-inter text-[#18181B] mb-2"
                  htmlFor="proposalFrom"
                >
                  Who is this from
                </label>
                <Field
                  name="proposalFrom"
                  type="text"
                  id="proposalFrom"
                  className="w-full border border-[#E4E4E7] rounded-md px-4 py-4"
                />
                {errors.proposalFrom && touched.proposalFrom && (
                  <div className="text-red-500 text-sm">
                    {errors.proposalFrom}
                  </div>
                )}
              </div>

              {/* Input 2 */}
              <div className="flex gap-4 w-[60%]">
                <div className="flex-col">
                  <label
                    className="block text-sm font-medium font-inter text-[#18181B] mb-2"
                    htmlFor="billTo"
                  >
                    Bill To
                  </label>
                  <Field
                    name="billTo"
                    type="text"
                    id="billTo"
                    className="w-full border border-[#E4E4E7] rounded-md px-4 py-3"
                  />
                  {errors.billTo && touched.billTo && (
                    <div className="text-red-500 text-sm">{errors.billTo}</div>
                  )}
                </div>
              </div>
            </div>

            {/* Second Column */}
            <div className="w-full md:w-[25%] md:pl-4 mt-6 md:mt-0 text-right">
              {/* Input 3 */}
              <div className="mb-4 flex items-center justify-end">
                <label
                  className="text-sm font-medium font-inter text-[#18181B] mr-2 w-[25%] text-left"
                  htmlFor="paymentTerms"
                >
                  Payment terms
                </label>
                <Field
                  name="paymentTerms"
                  component="textarea"
                  id="paymentTerms"
                  className="w-full text-sm font-normal text-[#71717A] border border-[#E4E4E7] rounded-md px-4 py-3 placeholder-[#71717A]"
                />
              </div>

              {/* Input 4 */}
              <div className="mb-4 flex items-center justify-end">
                <label
                  className="text-sm font-medium font-inter text-[#18181B] mr-2 w-[25%] text-left"
                  htmlFor="date"
                >
                  Date
                </label>
                <Field
                  name="date"
                  type="date"
                  id="date"
                  className="w-[50%] border border-[#E4E4E7] rounded-md px-4 py-2"
                />
              </div>
              {errors.date && touched.date && (
                <div className="text-red-500 text-sm mb-2">{errors.date}</div>
              )}

              {/* Input 5 */}
              <div className="mb-4 flex items-center justify-end">
                <label
                  className="text-sm font-medium font-inter text-[#18181B] mr-2 w-[25%] text-left"
                  htmlFor="dueDate"
                >
                  Valid For (Days)
                </label>
                <Field
                  as="select"
                  name="dueDate"
                  type="number"
                  className="w-[50%] border border-[#E4E4E7] rounded-md px-4 py-2"
                >
                  <option value="">Select</option>
                  {proposalValidityDays.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </Field>
              </div>
              {errors.dueDate && touched.dueDate && (
                <div className="text-red-500 text-sm mb-2">
                  {errors.dueDate}
                </div>
              )}
            </div>

            {/* Items Section */}
            <div className="w-full mt-6">
              <FieldArray
                name="items"
                render={(arrayHelpers) => (
                  <div>
                    {/* Table headers */}
                    <div className="grid grid-cols-7 gap-2 bg-[#18181B] rounded-md text-white py-4 px-6 mb-6">
                      <div className="col-span-3">Items</div>
                      <div>Quantity</div>
                      <div>Unit</div>
                      <div>Price</div>
                      <div>Amount</div>
                    </div>

                    {values.items.map((item, index) => (
                      <div key={index} className="grid grid-cols-7 gap-4 p-2">
                        <div className="col-span-3">
                          <Field
                            placeholder="Item list"
                            name={`items[${index}].item`}
                            type="text"
                            className="w-full border border-[#E4E4E7] rounded-md px-4 py-2 placeholder-[#71717A]"
                          />
                          <ErrorMessage
                            name={`items[${index}].item`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div>
                          <Field
                            name={`items[${index}].quantity`}
                            type="number"
                            className="w-full border border-[#E4E4E7] rounded-md px-4 py-2"
                          />
                          <ErrorMessage
                            name={`items[${index}].quantity`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div>
                          <Field
                            name={`items[${index}].unit`}
                            type="text"
                            className="w-full border border-[#E4E4E7] rounded-md px-4 py-2"
                          />
                          <ErrorMessage
                            name={`items[${index}].unit`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div>
                          <Field
                            name={`items[${index}].price`}
                            type="number"
                            className="w-full border border-[#E4E4E7] rounded-md px-4 py-2"
                          />
                          <ErrorMessage
                            name={`items[${index}].price`}
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="flex justify-between gap-3">
                          <Field
                            name={`items[${index}].amount`}
                            type="text"
                            readOnly
                            value={`$${
                              values.items[index].quantity *
                                values.items[index].price || 0
                            }`}
                            className="w-full border border-[#E4E4E7] rounded-md px-4 py-2"
                          />
                          <button
                            disabled={values.items.length === 1}
                            onClick={() => arrayHelpers.remove(index)}
                            className="flex items-center justify-end"
                          >
                            <img src={CrossIconProposal} alt="Remove Item" />
                          </button>
                        </div>

                        {/* Add Description Toggle */}
                        <div className="col-span-3 ml-5">
                          {/* Description input */}
                          {item.showDescription && (
                            <div className="ml-5">
                              <Field
                                name={`items[${index}].description`}
                                type="text"
                                placeholder="Add description here"
                                className="w-full border border-[#E4E4E7] rounded-md px-4 py-2 placeholder-[#71717A]"
                              />
                            </div>
                          )}
                          <button
                            type="button"
                            className={`text-[#18181B] text-sm font-normal mt-2 ml-5 inline-flex items-center space-x-2`}
                            onClick={() => {
                              const newItems = [...values.items];
                              newItems[index].showDescription =
                                !newItems[index].showDescription;
                              setFieldValue("items", newItems);
                            }}
                          >
                            {item.showDescription ? (
                              <span className="text-2xl">-</span>
                            ) : (
                              <img src={PlusIcon} alt="Add description" />
                            )}

                            <span className="font-inter text-[14px] font-normal leading-[20px]">
                              {item.showDescription
                                ? "Remove Description"
                                : "Add Description"}
                            </span>
                          </button>
                        </div>
                      </div>
                    ))}

                    {/* Button to add more rows */}
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          item: "",
                          quantity: "",
                          unit: "",
                          price: "",
                          amount: "",
                          description: "",
                          showDescription: false,
                        })
                      }
                      className="bg-[#18181B] text-white px-4 py-2 rounded-md mt-4"
                    >
                      + Add new list
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="mt-4 flex justify-center w-full">
              <div className="w-full border-t border-[#E4E4E7]"></div>
            </div>

            <div className="mt-8 flex justify-between w-full flex-wrap gap-4">
              {/* Left Column - Aligned to Left */}
              <div className="w-full md:w-[48%]">
                {/* Row 1 */}
                <div className="mb-6">
                  <label
                    htmlFor="notes"
                    className="text-sm font-medium text-[#18181B] block mb-2"
                  >
                    Notes
                  </label>
                  <Field
                    name="notes"
                    component="textarea"
                    placeholder="Notes - any relevant information not already covered"
                    className="w-full text-sm font-normal text-[#71717A] border border-[#E4E4E7] rounded-md px-4 py-3 placeholder-[#71717A]"
                  />
                </div>

                {/* Row 2 */}
                <div>
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium text-[#18181B] block mb-2"
                  >
                    Terms
                  </label>
                  <Field
                    name="terms"
                    component="textarea"
                    placeholder="Terms and conditions, late fees, payment methods"
                    className="w-full text-sm font-normal text-[#71717A] border border-[#E4E4E7] rounded-md px-4 pt-3 pb-9 placeholder-[#71717A]"
                  />
                  {errors.terms && touched.terms && (
                    <div className="text-red-500 text-sm mb-2">
                      {errors.terms}
                    </div>
                  )}
                </div>
              </div>

              {/* Right Column - Aligned to Right */}
              <div className="w-full md:w-[48%] flex flex-col items-end pt-10">
                <div className="mb-4 w-full">
                  <div className="flex justify-between">
                    <label
                      htmlFor="subTotal"
                      className="font-inter font-medium text-[18px] leading-[20px] text-[#18181B] block"
                    >
                      Sub Total
                    </label>
                    <p className="font-inter font-medium text-[18px] leading-[20px] text-[#18181B] w-1/2 text-right">
                      USD {calculateSubtotal(values.items)}
                    </p>
                  </div>
                </div>

                <div className="w-full">
                  <div className="flex justify-between">
                    <label
                      htmlFor="total"
                      className="font-inter font-medium text-[24px] leading-[20px] text-[#18181B] block"
                    >
                      Total
                    </label>
                    <p className="font-inter font-bold text-[24px] leading-[20px] text-[#18181B] w-1/2 text-right">
                      USD {calculateSubtotal(values.items)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Signature Section */}
              <div className="flex flex-col">
                <p className="text-sm font-medium text-[#18181B] block mb-2">
                  Signature
                </p>

                <div className="border border-gray-300 rounded-md w-80 h-32 flex items-center justify-center bg-white">
                  <SignatureCanvas
                    ref={sigPad}
                    penColor="black"
                    canvasProps={{ className: "w-full h-full" }}
                  />
                </div>

                {/* Buttons */}
                <div className="mt-4 flex gap-4">
                  <button
                    type="button"
                    onClick={clearSignature}
                    className="bg-gray-300 text-black px-4 py-2 rounded-md"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-4 flex justify-end w-full">
              <div className="flex pb-[100px]">
                <button
                  type="submit"
                  className="bg-[#18181B] text-white px-6 py-2 rounded-md"
                >
                  Submit Proposal
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateProposal;
